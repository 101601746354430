@font-face {
    font-family: 'digital-clock-font';
    /* src: url('../Font/digital-7/digital-7.ttf or .otf'); */
    /* src: url('../Font/GameBg.png'); */
}

.poker-table-box {
    position: absolute;
}

.game-page {
    padding: 0rem;
    background-color: #19191D;
    /* background-image: url('../Images/GameBg.png');
    background-size: cover;
    background-repeat: no-repeat; */
    height: 100%;
    width: 100%;
    position: fixed;
}

.collectChipsImage {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    height: auto !important;
    width: 5vw;
}


.collectChipsImage img {
    width: 2.2vw !important;
}

.collectChipsImage p {
    margin: 0;
    margin-left: 0.1vw;
    color: #fff;
    font-weight: 500;
    font-size: 0.8vw;
}

.mobileView .collectChipsImage {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    height: auto !important;
    width: 5vh;
}


.mobileView .collectChipsImage img {
    width: 2.8vh !important;
}

.mobileView .collectChipsImage p {
    margin: 0;
    margin-left: 0.1vh;
    color: #fff;
    font-weight: 500;
    font-size: 1.2vh;
}

.chipsImage {
    position: absolute;
    z-index: 1;
    text-align: center;
    align-items: center;
    height: auto !important;
}


.chipsImage img {
    margin: 0;
    width: 1.5vw !important;
}

.chipsImage p {
    margin: 0;
    color: #ffffff9f;
    font-weight: 500;
    font-size: 0.8vw;
}

.mobileView .chipsImage {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    display: block;
    align-items: center;
    height: auto !important;
}

.mobileView .chipsImage img {
    width: 1.5vh !important;
}

.mobileView .chipsImage p {
    margin: 0;
    margin-left: 0.2vw;
    color: #fff;
    font-weight: 500;
    font-size: 1.2vh;
}


.fill-seat-wrapper {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
}

.fill-seat-wrapper .playerAvatarImg {
    z-index: 111;
}


.empty-seat-wrapper {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    /* border-radius: 50%;
    box-shadow:  2px 8px rgba(0, 0, 0, 0.5), inset 0 0 4px 1px rgba(255, 255, 255, 0.15) */
}

.gameTable {
    height: 100%;
    width: auto;
}

.gameTable img {
    height: 100%;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.p-sit-btn {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 20vh;
    width: 12vw;
}

.p-sit-btn {
    height: 9vh;
    width: 12vw;
    position: absolute;
}

.main-btn-box {
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

.main-btn-box .sit-info {
    height: 100%;
    width: 100%;
    background-image: url('../Images/ButtonBg.png');
    background-size: 100% 100%;
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.25);
    vertical-align: middle;
}

.main-btn-box.right .sit-info {
    border-radius: 40px !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
}

.main-btn-box.left .sit-info {
    border-radius: 20px !important;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}

.main-btn-box.right .sit-info {
    padding-left: 11vh;
}

.main-btn-box.right .sit-info p {
    text-align: left;
    color: #fff;
    font-size: 3vh;
    height: 9vh;
    margin: 0px;
    display: table-cell;
    vertical-align: middle;
}

.main-btn-box.left .sit-info {
    padding-left: 3vh;
}

.main-btn-box.left .sit-info p {
    text-align: right;
    color: #fff;
    font-size: 3vh;
    height: 9vh;
    display: table-cell;
    vertical-align: middle;
}

.main-btn-box .profilePic {
    position: absolute;
    border-radius: 50%;
    height: 100%;
    width: 9vh;
    background-image: url('../Images/UserPlaceHolder.png');
    background-size: 100% 100%;
    vertical-align: middle;
}

.p-sit-btn .right .profilePic {
    left: 0;
    top: 0;
}

.p-sit-btn .left .profilePic {
    right: 0;
    top: 0;
}


.p-sit-btn-fill p {
    color: #fff;
    align-items: center;
    text-align: center;
}

.p-sit-1 {
    margin: 0;
    top: 18vh;
    left: 50vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-2 {
    margin: 0;
    top: 22vh;
    right: 15vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-3 {
    margin: 0;
    top: 50vh;
    right: 8vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-4 {
    margin: 0;
    bottom: 12vh;
    right: 15vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.p-sit-5 {
    bottom: 10vh;
    left: 51vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-6 {
    margin: 0;
    bottom: 12vh;
    left: 27vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-7 {
    margin: 0;
    top: 50vh;
    left: 20vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-8 {
    margin: 0;
    top: 22vh;
    left: 27vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.buyInModal {
    overflow: auto;
    color: #fff;
    text-align: center;
}

.buyInModal thead th {
    position: sticky;
    top: 0;
}

.buyInModal::-webkit-scrollbar {
    display: none;
}

.buyInModal {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.buyInModal table td,
.buyInModal table th {
    text-align: start !important;
}

.chipsDetails p {
    color: #fff;
    margin: 0;
    font-size: 1rem;
}

.mobileView .chipsDetails p {
    font-size: 1.5vh;
}

.mobileView .chipsDetails span {
    font-size: 1.5vh;
}


.gameModel {
    position: relative;
    height: 100%;
}

.gameModel .modalBody {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}


.gameModel h2 {
    font-size: 2vw !important;
}

.mobileView h2 {
    font-size: 2vh !important;
}

.gameModel h4 {
    font-size: 1.5vw !important;
}

.mobileView h4 {
    font-size: 1.5vh !important;
}

.gameModel .mobileView h2 {
    font-size: 2vh !important;
}

.gameModel .mobileView h4 {
    font-size: 1.5vh !important;
}

.chipsDetails .minBuyIn {
    text-align: right;
}

.chipsDetails .maxBuyIn {
    text-align: left;
}

.chipsBuyIn input {
    color: #fff;
    font-size: 1.5vw !important;
    font-weight: bold;
    background: transparent;
    border: none;
    width: 100%;
    text-align: center;
    outline: none;
}

.chipsBuyIn {
    background-color: #4c486f;
    border-radius: 5px;
    padding: auto;
    height: 3.5vw;
    line-height: 3.5vw;
    text-align: center;
    margin-top: 0.5vw;
}

.mobileView .chipsBuyIn {
    border-radius: 5px;
    height: 4vh;
    line-height: 4vh;
    margin-top: 0.5vh;
}

.mobileView .chipsBuyIn input {
    font-size: 1.5vh !important;
    font-weight: 700;
}


.sliderInput {
    background-color: #222227;
    border: 2px solid #26262C;
    padding: 0.5vw;
    border-radius: 50px;
    margin: 1.5rem 10%;
}

.sliderInput input {
    font-size: 1vw;
}

.selectBuyInBtn {
    /* border: 2px solid #fff !important; */
    border: none !important;
    padding: 2% !important;
    padding-left: 2vw !important;
    padding-right: 2vw !important;
    border-radius: 0.5vw !important;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 2.5vh !important;
    text-align: start !important;
    font-weight: 600 !important;
}

.mobileView .selectBuyInBtn {
    /* border: 2px solid #fff !important; */
    border: none !important;
    padding: 3% !important;
    padding-top: 2% !important;
    border-radius: 0.5vh !important;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 1.5vh !important;
    line-height: 1.5vh !important;
    text-align: start !important;
    font-weight: 500 !important;
}


.playerDetails hr {
    margin: 0;
    width: 100%;
}

.backCardImgLeft {
    bottom: 40%;
    right: 90%;
}

.backCardImgRight {
    bottom: 40%;
    left: 90%;
}

.animation-card-image {
    position: absolute;
    z-index: 1;
    transition: all 0.05s;
    perspective: 1000;
    -ms-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -ms-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;

}

/* 

.card-image-animation {
    animation: slide-up 1.5s;
} */

.card-image {
    position: absolute;
    transform: translate3d(50%, 100%);
    z-index: 1;
}

.mobileView .card-image {
    position: absolute;
    transform: translate3d(50%, 100%);
    z-index: 1;
}

.frontFirstCard {
    bottom: 25%;
    right: 70%;
    transform: rotate(-45deg);
}

.frontSecondCard {
    bottom: 55%;
    right: 40%;
    transform: rotate(-15deg);
}

.frontThirdCard {
    bottom: 55%;
    right: -5%;
    transform: rotate(15deg);
}

.frontFourCard {
    bottom: 25%;
    right: -40%;
    transform: rotate(45deg);
}

.backFirstCard {
    bottom: 30%;
    right: 60%;
    transform: rotate(-30deg);
}

.backSecondCard {
    bottom: 50%;
    right: 40%;
    transform: rotate(-10deg);
}

.backThirdCard {
    bottom: 50%;
    right: 10%;
    transform: rotate(10deg);
}

.backFourCard {
    bottom: 30%;
    right: -10%;
    transform: rotate(30deg);
}


.mobileView .frontFirstCard {
    bottom: 35%;
    right: 70%;
    transform: rotate(-45deg);
}

.mobileView .frontSecondCard {
    bottom: 58%;
    right: 44%;
    transform: rotate(-15deg);
}

.mobileView .frontThirdCard {
    bottom: 58%;
    right: 10%;
    transform: rotate(15deg);
}

.mobileView .frontFourCard {
    bottom: 35%;
    right: -18%;
    transform: rotate(45deg);
}

.mobileView .backFirstCard {
    bottom: 30%;
    right: 60%;
    transform: rotate(-30deg);
}

.mobileView .backSecondCard {
    bottom: 50%;
    right: 40%;
    transform: rotate(-10deg);
}

.mobileView .backThirdCard {
    bottom: 50%;
    right: 10%;
    transform: rotate(10deg);
}

.mobileView .backFourCard {
    bottom: 30%;
    right: -10%;
    transform: rotate(30deg);
}


.mobileView .playerDetails {
    color: #fff;
    background-image: url('../Images/Icons/PlayerInfoBg.png');
    background-size: 100% 100%;
    padding: 0% !important;
    position: absolute;
    z-index: 1;
    bottom: -50%;
    width: 150%;
    right: -25%;
    text-align: center;
    z-index: 1111111;
}


.mobileView .playerDetails p {
    margin: 0;
    white-space: nowrap;
    font-size: 1.3vh;
}

.playerDetails hr {
    margin: 0;
    width: 100%;
}

.playerDetails .dealerIconRight {
    position: absolute;
    top: -30%;
    right: -10%;
    width: 2vw;
    height: 2vw;
}

.playerDetails .dealerIconLeft {
    position: absolute;
    top: -30%;
    left: -10%;
    width: 2vw;
    height: 2vw;
}

.mobileView .playerDetails .dealerIconRight {
    position: absolute;
    top: -15%;
    right: -15%;
    width: 2.5vh;
    height: 2.5vh;
}

.mobileView .playerDetails .dealerIconLeft {
    position: absolute;
    top: -15%;
    width: 2.5vh;
    height: 2.5vh;
}

.card-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
}


.footerSliderInput {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.footerSliderBox {
    display: flex;
    gap: 0.5rem;
}

.raiseInput {
    padding-left: 1rem;
    padding-right: 1rem;
    /* background-color: #4c486f; */
    background-color: #222227;
    padding: 0.5vh;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.raiseInput span {
    color: #fff;
}

.mobileView .raiseInput {
    display: block;
    height: 100%;
}

.mobileView .raiseInput input[type="number"] {
    border: none !important;
    text-align: center;
}

.raiseBtn {
    font-size: 2.5vh !important;
    height: 3.5vw;
    width: 3.5vw;
    background-color: #222227;
    /* background-image: url('../Images/BtnBg.png');
    background-size: 110% 110%;
    background-repeat: no-repeat; */
    color: #fff !important;
    border: 2px solid #26262C;
    font-weight: 600 !important;
    outline: none;
    border: none;
    align-items: center;
    text-align: center;
    border-radius: 1vh;
}


.raiseChips input {
    color: #fff;
    font-size: 1.2vw !important;
    font-weight: bold;
    background: transparent;
    border: none;
    width: 100%;
    text-align: center;
    outline: none;
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.raiseChips {
    border-radius: 1vh;
    text-align: center;
    height: 2.5vw;
    line-height: 2.5vw;
    text-align: center;
}


.mobileView .raiseBtn {
    font-size: 1.5vh !important;
    height: 4.5vh;
    width: 4.5vh;
}

.mobileView .raiseChips input {
    color: #fff;
    font-size: 1.5vh !important;
    font-weight: bold;
    background: transparent;
    border: none;
    width: 100%;
    text-align: center;
    outline: none;
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}


.mobileView .raiseChips {
    border-radius: 1vh;
    text-align: center;
    height: 2.5vh;
    line-height: 2.5vh;
    text-align: center;
}

.footerAction {
    position: absolute;
    bottom: 2%;
    right: 30%;
    left: 30%;
    width: 40%;
    text-align: center;
}

.mobileView .footerAction {
    position: absolute;
    bottom: 2%;
    right: 10%;
    left: 10%;
    width: 80%;
    text-align: center;
}

.footerActionBtn {
    display: flex;
}

.footerActionCheckBoxLeft {
    position: absolute;
    bottom: 2px;
    width: 30%;
}

.footerActionCheckBoxRight {
    position: absolute;
    bottom: 10px;
    left: 30%;
    width: 70%;
}

.mobileView .footerActionCheckBoxLeft {
    position: absolute;
    bottom: 2px;
    width: 35%;
}

.mobileView .footerActionCheckBoxRight {
    position: absolute;
    bottom: 10px;
    left: 35%;
    width: 60%;
}

.actionBtns {
    float: left;
}

.actionBar {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    background-color: #ffffff7e;
    padding: 10px;
    padding-top: 15px;
    margin: 5px;
    border: 1px solid #fff;
    border-radius: 5px;
}

.footerBtn {
    font-size: 1.5vw;
    padding: 0.5vw 1.2vw;
    outline: none;
    margin: 0rem 0.2rem;
    transition: 0.5s;
    background-size: auto 200%;
    color: white;
    background: #222227;
    border: 2px solid #26262C;
    width: 100%;
    border-radius: 10px;

}

.raiseBtn:hover,
.raiseBtn:focus {
    background: #8025f3;
    border: 2px solid #8025f3;
}

.footerBtn:hover,
.footerBtn:focus {
    background: #8025f3;
    border: 2px solid #8025f3;
}

.mobileView .footerBtn {
    font-size: 1.5vh;
    padding: 0.5vh 1.2vh;
    width: 100%;
}

/* .mobileView .footerBtn {
    font-size: 1.7vw;
    padding: 0.5vw 1.2vw;
    outline: none;
    border: none;
    margin: 0rem 0.2rem;
    transition: 0.5s;
    background-size: auto 200%;
    color: white;
    background: transparent;
} */

.footerBtn:hover {
    background-position: bottom center;
    /* change the direction of the change here */
}

/* .btnSuccess {
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.btnDefault {
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.btnDanger {
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
} */




@keyframes slide-up {
    0% {
        transform: translate3d(0, 50%, 0);
    }

    50% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(50%);
    }
}



.tablePot {
    position: absolute;
    text-align: center;
    color: #fff;
    font-weight: 500;
    padding: 0.2rem;
    border-radius: 15px;
    width: 7vw;
    font-size: 0.8vw;
}

.tablePot::before {
    content: "";
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: 1;
    border-radius: 15px;
}

/* 
.tablePot span {
    z-index: 1;
    opacity: 1;
    font-size: 0.8vw;
    vertical-align: middle;
} */


.mobileView .tablePot {
    top: 25%;
    width: 25%;
    font-weight: 500;
    font-size: 1.2vh;
}


.hiddenCard img {
    width: 100%;
    height: auto !important;
}

.hiddenCard {
    position: absolute;
    z-index: 1;
}

.hiddenCardShuffle {
    z-index: 11111;
}

.mobileView .mainhiddenCard {
    width: 8% !important;
}

.hiddenCard1 {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
}

.hiddenCard2 {
    transform: translateX(0%);
    -webkit-transform: translateX(-100%);
}

.hiddenCard3 {
    transform: translateX(0%);
    -webkit-transform: translateX(-200%);
}

.hiddenCard4 {
    transform: translateX(0%);
    -webkit-transform: translateX(-300%);
}

.hiddenCard5 {
    transform: translateX(0%);
    -webkit-transform: translateX(-400%);
}

.slide-in {
    animation: slide-in 0.8s forwards;
    -webkit-animation: slide-in 0.8s forwards;
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(0%);
    }
}

.flip-card-inner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
}


.flip-card-back {
    transform: rotateY(180deg);
}

.hiddenCard.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}



@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}




.winnerType {
    position: absolute;
    top: 26%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #fff;
    font-size: 1.1vw;
    padding: 0.2rem 0.2rem;
    background-color: #000;
    width: 25%;
}

.mobileView .winnerType {
    top: 35%;
    font-size: 1.2vh;
    width: 35%;
}

.winnerImage {
    animation: blink 1s;
    animation-iteration-count: infinite;
    position: absolute;
    bottom: -15%;
    right: -20%;
    width: 140% !important;
    height: auto !important;
    z-index: 1111111;
}

input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    border-radius: 20px;
    background-color: #2D2D34;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1.5vh;
    cursor: pointer;
    animate: 0.2s;
    border-radius: 50px;
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-thumb {
    /* box-shadow: 0px -0px 6px 6px #717592; */
    height: 3.5vh;
    width: 3.5vh;
    border-radius: 23px;
    background-color: #fff;
    /* background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat; */
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -1vh;
}

.mobileView input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    border-radius: 20px;
}

.mobileView input[type=range]:focus {
    outline: none;
}

.mobileView input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1vh;
    cursor: pointer;
    animate: 0.2s;
    border-radius: 20px;
}

.mobileView input[type=range]::-webkit-slider-thumb {
    height: 2vh;
    width: 2vh;
    border-radius: 23px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.5vh;
}


.gameBtn {
    position: absolute;
    top: 1%;
    right: 0%;
    display: flex;
}

.iAmBackBtn {
    position: absolute;
    bottom: 1%;
    right: 1%;
    background: transparent;
    outline: none;
    display: flex;
    background-size: auto 200%;
    color: white;
    background: #222227;
    border: 2px solid #26262C;
    border-radius: 10px;
    padding: 1vw;
    padding-right: 1.5vw;
    color: #fff;
    white-space: nowrap;
    font-size: 1.5vw;
}

.mobileView .iAmBackBtn {
    padding: 1vw;
    font-size: 1.5vh;
}

.addChipBtn {
    background: transparent;
    outline: none;
    color: #fff;
    white-space: nowrap;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-right: 1rem;
}

.addChipBtn img {
    height: 3vw !important;
}

.mobileView .addChipBtn {
    margin-right: 0.5rem;
}

.mobileView .addChipBtn img {
    height: 2.5vh !important;
}

.gameLeaveBtn {
    background: transparent;
    border: none;
    outline: none;
}

.gameLeaveBtn img {
    border-radius: 50%;
    width: 7vh;
    height: 7vh;
}

.mobileView .gameLeaveBtn img {
    border-radius: 50%;
    width: 4vh;
    height: 4vh;
}

.overlay {
    position: absolute;
    bottom: 0;
    background: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.5);
    /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: .5s ease;
    opacity: 1;
    color: white;
    font-size: 20px;
    text-align: center;
    z-index: 11111;
}




/* ================================== */



.game-checkbox .form-control {
    background-color: transparent;
    border: none;
    font-size: 1.5vw;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 1vw;
    color: #fff;
    width: auto !important;
}



.game-checkbox input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--form-background);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.game-checkbox input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    background-color: #8025F3;
}

.game-checkbox input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.mobileView .game-checkbox .form-control {
    background-color: transparent;
    border: none;
    font-size: 1.2vh;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 1vw;
    color: #fff;
    width: auto !important;
    padding: 0.5rem;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.playerActionName {
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-direction: alternate;
    font-weight: 700;
    color: #fff;
    font-size: 1.5vw;
}




.range-slider-wrapper .range-slider {
    position: relative;
    z-index: 1;
}

.range-slider-wrapper .vertical {
    transform: rotate(90deg);
    transform-origin: left top;
}

.labels {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2px;
    justify-content: space-between;

}

.labels div {
    height: 28px;
    line-height: normal;
    display: flex;
    align-items: center;

}

.labels div span {
    display: block;
    width: 20px;
    height: 2px;
    background: black;
    margin-right: 10px;
}

input[type="range"].range-slider {
    width: 100%;
    background-color: transparent;
    -webkit-appearance: none;
}

input[type="range"].range-slider:focus {
    outline: none;
}

input[type="range"].range-slider::-webkit-slider-runnable-track {
    background: #481e4c;
    border: 1px solid #000000;
    border-radius: 10px;
    width: 100%;
    height: 13px;
    cursor: pointer;
}

input[type="range"].range-slider::-webkit-slider-thumb {
    margin-top: -7.5px;
    width: 26px;
    height: 26px;
    background: #db005e;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 15px;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type="range"].range-slider:focus::-webkit-slider-runnable-track {
    background: #4b1f50;
}

input[type="range"].range-slider::-moz-range-track {
    background: #481e4c;
    border: 1px solid #000000;
    border-radius: 10px;
    width: 100%;
    height: 13px;
    cursor: pointer;
}

input[type="range"].range-slider::-moz-range-thumb {
    width: 26px;
    height: 26px;
    background: #db005e;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 15px;
    cursor: pointer;
}

input[type="range"].range-slider::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 6.5px 0;
    color: transparent;
    width: 100%;
    height: 13px;
    cursor: pointer;
}

input[type="range"].range-slider::-ms-fill-lower {
    background: #451d48;
    border: 1px solid #000000;
    border-radius: 20px;
}

input[type="range"].range-slider::-ms-fill-upper {
    background: #481e4c;
    border: 1px solid #000000;
    border-radius: 20px;
}

input[type="range"].range-slider::-ms-thumb {
    width: 26px;
    height: 26px;
    background: #db005e;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}

input[type="range"].range-slider:focus::-ms-fill-lower {
    background: #481e4c;
}

input[type="range"].range-slider:focus::-ms-fill-upper {
    background: #4b1f50;
}

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {

    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type="range"].range-slider {
        margin: 0;
        /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
}

.gameBtn .userTime {
    color: #fff;
    font-size: 1.2vw;
    margin-right: 15px;
}

.mobileView .gameBtn .userTime {
    color: #fff;
    font-size: 1.5vh;
    margin-right: 15px;
}



























/* New Dev */


.gameTableHeader {
    padding: 1vw;
    padding-bottom: 0.5vw;
    display: flow-root;
}

.gameTableHeader .backIcon {
    /* width: 2.5vw;
    height: 2.5vw; */
    vertical-align: middle;
    align-items: center;
    padding: 0px;
    margin: 0px;
}


.gameTableHeader .leftPanel {
    display: flex;
    vertical-align: middle;
    align-items: center;
    float: left;
    display: flex;
    gap: 1vw;
    vertical-align: middle;
    align-items: center;
}

.gameTableHeader .rightPanel {
    display: flex;
    vertical-align: middle;
    align-items: center;
    float: right;
    display: flex;
    gap: 1vw;
    vertical-align: top;
    align-items: center;
}

.mobileView .gameTableHeader .rightPanel {
    display: flex;
    vertical-align: middle;
    align-items: center;
    float: right;
    display: flex;
    gap: 1vh;
    vertical-align: top;
    align-items: center;
}

.gameTableHeader .backIcon button,
.gameTableHeader .backIcon img {
    width: 100%;
    height: 100%;
}


.mobileView .gameTableHeader {
    padding: 0.5vh;
    padding-bottom: 0.5vw;
    display: flow-root;
}

.mobileView .gameTableHeader .backIcon {
    width: 3vh;
    height: 3vh;
    vertical-align: middle;
    align-items: center;
    padding: 0px;
    margin: 0px;
    margin-top: 7px;
}

.plainBtn {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
}

.playerLeftHours {
    background-image: url('../Images/Icons/TimeBG.png');
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    padding: 5px 10px;
    gap: 1vw;
    align-items: center;
}

.playerLeftHours .align-item-center {
    align-items: center;
    gap: 5px;
}

.playerLeftHours .timeIcon {
    width: 18px;
    height: 18px;
}




.mobileView .playerLeftHours {
    background-image: url('../Images/Icons/TimeBG.png');
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    padding: 2.5px 5px;
    gap: 1vw;
    align-items: center;
}

.mobileView .playerLeftHours .align-item-center {
    align-items: center;
    gap: 5px;
}

.mobileView .playerLeftHours .timeIcon {
    width: 20px;
    height: 20px;
}

.mobileView .playerLeftHours .buyHourBtn {
    width: 30px;
    height: 30px;
}







.tableDetails {
    /* background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(130, 37, 244)); */
    background-color: #24242c;

    position: absolute;
    right: 0;
    margin-top: 0.5vw;
    text-align: right;
    padding: 0.2vw 1vw;
    padding-left: 1.5vw;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border: 1px solid #242234;
}

.tableDetails .color-1 {
    color: #fff;
}

.tableDetails .color-2 {
    color: #ffffff9d;
}

.tableDetails p {
    margin-bottom: 0px;
    font-size: 0.8vw;
}

.mobileView .tableDetails {
    margin-top: 0.5vh;
    margin-bottom: 0px;
    font-size: 1.5vh;
}

.mobileView .tableDetails p {
    margin-bottom: 0px;
    font-size: 1.5vh;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    background-image: url('../Images/Icons/TimeBG.png');
    background-size: 100% 100%;
    color: #74747C;
    position: absolute;
    padding: 5px 10px;
    bottom: -1.6em;
    left: 0em;
    white-space: nowrap;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
}

.muteTitle {
    font-size: 0.7vw;
    color: #74747C;
}


.playerAction {
    color: #8025F3;
    position: absolute;
    z-index: 1;
    bottom: -20%;
    width: 80%;
    right: 10%;
    bottom: -95%;
    text-align: center;
    z-index: 11111111;
    border-radius: 10px;
    font-size: 0.9vw;
    font-weight: 700;
}

.playerAction::before {
    content: "";
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: -1;
}


.mobileView .playerAction {
    font-size: 1.2vh;
    bottom: -100%;
}


.playerDetails {
    color: #fff;
    padding: 5px 0px 2px 0px;
    position: absolute;
    z-index: 1;
    bottom: -53%;
    width: 160%;
    right: -30%;
    border-radius: 10px;
    text-align: center;
}

.playerDetails::before {
    content: "";
    background-image: url('../Images/Icons/PlayerInfoBg.png');
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: -1;
}

.playerDetails p {
    position: relative;
    z-index: 1;
    opacity: 1;
    font-size: 0.8vw;
    margin-top: 0.150vw;
    margin-bottom: 0vw;
}

.playerDetails .playeChips {
    color: #74747C;
}

.playerDetails .dealerIconRight {
    position: absolute;
    top: -30%;
    right: -10%;
    width: 2vw;
    height: 2vw;
}

.playerDetails .dealerIconLeft {
    position: absolute;
    top: -30%;
    left: -10%;
    width: 2vw;
    height: 2vw;
}

.displayForcly {
    z-index: 111111;
}



/* .countdownContainer {
    border: 1px solid black;
    border-radius: 3px;
    width: 100px;
    height: 30px;
    display: block;
    background: linear-gradient(to left, black 50%, white 50%);
    background-size: 200% 100%;
    z-index: 11111;
    background-position: right bottom;
    transition: all 30s ease-out;
} */

.countdownContainer {
    border-radius: 50px;
    color: #fff;
    padding: 5px 0px 2px 0px;
    position: absolute;
    z-index: 1;
    bottom: -53%;
    width: 160%;
    right: -30%;
    text-align: center;
    display: block;
    background: linear-gradient(to right, #7825F3 50%, transparent 0%);
    background-size: 200% 100%;
    z-index: 11;
    background-position: right bottom;
    /* transition: 'background-position ' */
}

.countdownContainer::before {
    content: "";
    background-image: url('../Images/Icons/PlayerInfoBg.png');
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: -1;
}

.countdownContainer p {
    position: relative;
    z-index: 1;
    opacity: 1;
    font-size: 0.8vw;
    margin-top: 0.150vw;
    margin-bottom: 0vw;
}

.countdownContainer .playeChips {
    color: #74747C;
}

.countdownContainer .dealerIconRight {
    position: absolute;
    top: -30%;
    right: -10%;
    width: 2vw;
    height: 2vw;
}

.playerDetails .dealerIconLeft {
    position: absolute;
    top: -30%;
    left: -10%;
    width: 2vw;
    height: 2vw;
}



/* .countdownContainer {
    display: "flex";
    justify-content: "center";
    align-items: "center";
    position: "relative";
    margin: "auto";
}

.countdownContainer svg {
    position: "absolute";
    top: 0;
    left: 0;
    width: "100%";
    height: "100%";
    transform: "rotateY(-180deg) rotateZ(-90deg)";
    overflow: "visible";
} */


/* transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
 */
/* width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    will-change: transform; */

.winner-profile {
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #7825F3;
    box-shadow: 0 0 20px rgb(120, 37, 243);
    animation: winner-animation 0.5s ease-in-out infinite;
}

/* Profile image */
.profile-image {
    border-radius: 50%;
    object-fit: cover;
}

@keyframes winner-animation {
    0% {
        /* transform: scale(1); */
        box-shadow: 0 0 20px rgba(120, 37, 243, 0.5);
    }

    50% {
        /* transform: scale(1.08); */
        box-shadow: 0 0 30px #fff;
    }

    100% {
        /* transform: scale(1); */
        box-shadow: 0 0 20px rgba(120, 37, 243, 0.5);
    }
}

.timeLeftFont {
    /* font-family: "digital-clock-font"; */
}

.headerBtn {
    border-radius: 50%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.8);
}

.lobbyTimeIcon {
    height: 100%;
}

.chatBtnBox {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1vw;
    padding-bottom: 0.5vw;
    z-index: 1111111;
}



.chatBar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1111111;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
    background-color: #000;
}

.chatBar a {
    display: block;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    transition: 0.3s;
}

.chatBar a:hover {
    color: #F1F1F1;
}

.chatBar .sideBarClosebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 35px;
    margin-left: 50px;
}

.chat-heading {
    color: #8025F3;
    text-align: center;
    text-transform: uppercase;
}

.chatBar hr {
    border: 2px solid #8025F3;
}

.msger * {
    overflow-anchor: none;
}

.msger {
    overflow-anchor: auto;
    height: 1px;
}


:root {
    --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    --msger-bg: #fff;
    --border: 2px solid #ddd;
    --left-msg-bg: #242424;
    --right-msg-bg: #8025F3;
}

.msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    height: 85%;
}

.msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.msger-chat::-webkit-scrollbar {
    width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
    background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
    background: #686868;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.msg-text {
    color: #fff;
    font-size: 0.8vw;
}

.msg:last-of-type {
    margin: 0;
}

.msg-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #ddd;
    border-radius: 50%;
}

.msg-bubble {
    max-width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    background: var(--left-msg-bg);
}

.msg-info-name {
    color: #fff;
    font-size: 0.6vw;
    font-weight: bold;
    text-align: left;
}

.right-msg .msg-info-name {
    text-align: right;
}

.msg-info-time {
    font-size: 0.85em;
}

.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background: var(--right-msg-bg);
    color: #fff;
    border-bottom-right-radius: 0;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}

.msger-inputarea {
    background: #242424;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.msger-input-box {
    display: flex;
    padding: 10px;
    background: #242424;
    width: 100%;
}

.msger-input {
    padding: 5px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
}

.msger-input {
    flex: 1;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
}

.msger-send-btn {
    cursor: pointer;
}