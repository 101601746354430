.color-1 {
    color: #fff;
}

.loader {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #242234;
}

.loader img {
    z-index: 1;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader .loading {
    z-index: 1;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 3vw;
}

.loader .progress-6 {
    z-index: 1;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.progress-6 {
    width: 40%;
    height: 2vw;
    border-radius: 20px;
    color: #fff;
    border: 2px solid;
    position: relative;
}

.progress-6::before {
    content: "";
    position: absolute;
    margin: 2px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: currentColor;
    animation: p6 6s infinite;
}


.mobileView .loader {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #242234;
}

.mobileView .loader img {
    z-index: 1;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mobileView .loader .loading {
    z-index: 1;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 3vh;
}

.mobileView .loader .progress-6 {
    z-index: 1;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.mobileView .progress-6 {
    width: 60%;
    height: 2vh;
    border-radius: 20px;
    color: #fff;
    border: 2px solid;
    position: relative;
}

.mobileView .progress-6::before {
    content: "";
    position: absolute;
    margin: 2px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: currentColor;
    animation: p6 6s infinite;
}


@keyframes p6 {
    100% {
        inset: 0
    }
}

/* .lobby-page:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: hsla(247, 21%, 17%, 0.9);
  } */
.lobby-page {
    padding: 0.5rem;
    /* background-image: url('../Images/login-bg.jpg'); */
    /* background-size: 100% ; */
    /* background-position: bottom; */
    background-color: #242234;
    height: 100vh;
    width: 100%;
    position: fixed;
}

.mobileView .lobby-page {
    padding: 0.5rem;
    background-color: #242234;
    height: 100%;
    min-height: 100vh;
    width: 100%;
}

.mainBody {
    width: 100vw;
    height: 100vh;
    position: fixed;
    max-height: 100vh;
}

@media only screen and (max-width : 320px) {
    /* .mainBody {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: '100vh';
        height: '100vw';
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        position: "fixed";
        max-height: "100vw";
    } */

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {}

.gameTitle {
    background-color: #4C935B;
    padding: 1rem;
    width: 60%;
}

.gameTitle h1 {
    color: #fff !important;
    font-size: 5vh;
}

.min-h-100 {
    min-height: 100% !important;
}


.p-col-2 {
    width: 16%;
}

.p-col-3 {
    width: 25%;
}

.p-col-4 {
    width: 33.33%;
}

.p-col-5 {
    width: 41.66%;
}


.p-col-6 {
    width: 50%;
}


.p-col-2-5 {
    width: 20%;
}

.p-col-9 {
    width: 80%;
}

.p-col-12 {
    width: 100%;
}

.p-hd_btn {
    border: 1px solid #fff !important;
    width: 100%;
    padding: 1.5vw !important;
    border-radius: 0.6vw !important;
}

.p-hd-fill-box {
    margin: 0.1rem;
    border-right: 1px solid #fff !important;
    width: 100%;
    padding: 2% !important;
    padding-left: 1vw !important;
    /* border-radius: 0.5vw !important; */
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    /* background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%; */
    color: #fff !important;
    font-size: 2.5vh !important;
    text-align: start !important;
    font-weight: 600 !important;
    display: flex;
    position: relative;
}

.border-none {
    border: none !important;
}

.p-hd-fill-box p {
    margin: 0;
    float: left;
    text-align: left;
    font-size: 1vw;
}

.p-hd-fill-box select {
    color: #fff !important;
    font-size: 1vw !important;
    text-align: start !important;
    font-weight: 600 !important;
    float: right;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: transparent;
    flex: 1;
    color: #fff;
    cursor: pointer;
    /* position:absolute;
right: 2vw; */
    text-align-last: right;
}

.p-hd-fill-box select option {
    font-size: 1vw;
    font-weight: 600 !important;
    text-align: center;
    background-color: #8025F3;
}

.mb-p-hd-fill-box {
    margin: 0.1rem;
    border-right: 1px solid #fff;
    width: 100%;
    color: #fff !important;
    font-size: 1.5vh !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.mb-p-hd-fill-box p {
    margin: 0;
    font-size: 1.5vh;
}

.mb-p-hd-fill-box select {
    color: #fff !important;
    font-size: 1.5vh !important;
    text-align: center !important;
    font-weight: 600 !important;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: transparent;
    flex: 1;
    color: #fff;
    cursor: pointer;
}

.mb-p-hd-fill-box select option {
    font-size: 1.5vh;
    font-weight: 600 !important;
    text-align: center;
    background-color: #8025F3;
}


.mb-p-hd-fill-box option:hover,
.mb-p-hd-fill-box option:active,
.mb-p-hd-fill-box option:focus {
    background-color: #c399f8 !important;
}

.p-hd-fill-btn {
    border: 1px solid #fff !important;
    width: 100%;
    padding: 2% !important;
    padding-left: 2vw !important;
    border-radius: 0.5vw !important;
    /* box-shadow: 0 0px 5px 0 rgba(255, 255, 255, 0.5), 0 0px 5px 0 rgba(255, 255, 255, 0.5);
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%; */
    color: #fff !important;
    font-size: 1.2vw !important;
    text-align: start !important;
    font-weight: 600 !important;
    z-index: 1;
    position: relative;
}


.p-hd-fill-btn:hover {
    background-image: url('../Images/LobbyButtonHover.png');
}

.p-hd-fill-btn.active {
    background-image: url('../Images/LobbyButtonHover.png');
}

.mobileView .p-hd-fill-btn {
    border-radius: 0.5vh !important;
    font-size: 1.5vh !important;
    text-align: center !important;
    font-weight: 400 !important;
    padding: 2% !important;
}

.payment-method-header-btn {
    border: none !important;
    border-bottom: 2px solid #757575 !important;
    width: 100%;
    border-radius: 0px !important;
    color: #757575 !important;
    font-size: 1vw !important;
    text-align: center !important;
    z-index: 1;
    position: relative;
    padding: 1rem;
}

.payment-method-header-btn.active {
    border-color: #8025F3 !important;
    color: #8025F3 !important;
}


.card-payment-details {
    width: 100%;
    border: 1px solid #757575 !important;
    padding: 10px;
    border-radius: 0.5vw !important;
}

.card-payment {
    width: 100%;
    border: 1px solid #757575 !important;
    padding: 10px;
    border-radius: 0.5vw !important;
}

.card-payment .d-flex .form-group {
    width: 100%;
    z-index: 1;
    align-items: center;
}

.payment-method-btn {
    border: 1px solid #757575 !important;
    width: 100%;
    border-radius: 0.5vw !important;
    /* box-shadow: 0 0px 5px 0 rgba(255, 255, 255, 0.5), 0 0px 5px 0 rgba(255, 255, 255, 0.5); */
    color: #757575 !important;
    font-size: 1vw !important;
    text-align: center !important;
    z-index: 1;
    align-items: center;
}

.payment-method-btn .payment-method-name {
    margin-top: 1rem;
    color: #757575 !important;
}

.payment-method-btn.active {
    border-color: #8025F3 !important;
    color: #8025F3 !important;
}

.payment-method-btn.active .payment-method-name {
    color: #8025F3 !important;
}

.payment-method-btn .icon {
    margin-top: 1rem;
    width: 100%;
    height: 3rem;
    align-items: center;
    display: flex;
    justify-content: center;
}

.payment-method-btn .icon img {
    width: 4rem;
}

.mobileView .payment-method-btn {
    font-size: 1.5vh !important;
}

.deposite-method-btn {
    /* border: 1px solid #fff !important; */
    width: 100%;
    /* box-shadow: 0 0px 5px 0 rgba(255, 255, 255, 0.5), 0 0px 5px 0 rgba(255, 255, 255, 0.5); */
    background-repeat: no-repeat;
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 1vw !important;
    text-align: center !important;
    z-index: 1;
    position: relative;
}


.deposite-method-btn:hover {
    background-image: url('../Images/LobbyButtonHover.png');
}


.mobileView .deposite-method-btn {
    font-size: 1.5vh !important;
}

.p-hd-fill-btn select {
    padding: 2% !important;
    padding-left: 2vw !important;
    border-radius: 0.5vw !important;
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 2.5vh !important;
    text-align: end !important;
    font-weight: 600 !important;
}

.p-hd-fill-btn select:hover {
    background-image: url('../Images/LobbyButtonHover.png');
}

.p-hd-fill-btn option {
    background-color: #8025F3;
    color: #fff !important;
    font-size: 2.5vh !important;
    text-align: start !important;
    font-weight: 600 !important;
}

.p-hd-fill-btn option:hover {
    background-color: #c399f8 !important;
}

.p-hd-fill-inner-btn {
    border: none !important;
    width: 100%;
    margin: 0.5rem;
    margin-bottom: 0rem !important;
    margin-left: 0rem !important;
    /* padding-left: 2vw !important; */
    border-radius: 0.5vw !important;
    /* box-shadow: 0 0px 5px 0 rgba(255, 255, 255, 0.5), 0 0px 5px 0 rgba(255, 255, 255, 0.5); */
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 1.2vw !important;
    text-align: start !important;
    font-weight: 600 !important;
    z-index: 1;
    position: relative;
}



.mobileView .p-hd-fill-inner-btn {
    /* border: 1px solid #fff !important; */
    width: 100%;
    padding: 1% !important;
    border-radius: 0.5vh !important;
    color: #fff !important;
    font-size: 1.2vh !important;
    text-align: center !important;
    font-weight: 400 !important;
    margin: 0px;
}


.p-hd-fill-inner-btn:hover {
    background-image: url('../Images/LobbyButtonHover.png');
}

.p-hd-fill-inner-btn.active {
    background-image: url('../Images/LobbyButtonHover.png');
}


.p-hd-fill-inner-btn select {
    padding: 2% !important;
    padding-left: 2vw !important;
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 2.5vh !important;
    text-align: end !important;
    font-weight: 600 !important;
}

.p-hd-fill-inner-btn select:hover {
    background-image: url('../Images/LobbyButtonHover.png');
}

.p-hd-fill-inner-btn option {
    background-color: #8025F3;
    color: #fff !important;
    font-size: 2.5vh !important;
    text-align: start !important;
    font-weight: 600 !important;
}

.p-hd-fill-inner-btn option:hover {
    background-color: #c399f8 !important;
}



.p-hd-profile-btn {
    padding: 2% !important;
    padding-left: 0 !important;
    font-size: 2.5vh !important;
    text-align: center !important;
}

.join-td {
    padding: 0.4rem 0.5rem !important;
}

.p-tb-join-btn {
    /* border: 1px solid #fff !important; */
    width: 100%;
    padding: 0vw !important;
    border-radius: 0.5vw !important;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    background-image: url('../Images/JoinButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 1rem !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.mobileView .p-tb-join-btn {
    /* border: 1px solid #fff !important; */
    width: 100%;
    padding: 0vw !important;
    border-radius: 0.5vh !important;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    background-image: url('../Images/JoinButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 1rem !important;
    text-align: center !important;
    font-weight: 600 !important;
    display: inline-block;
    vertical-align: baseline;
}

.p-tb-open-table-btn {
    /* border: 1px solid #fff !important; */
    border: none;
    width: auto;
    padding: 0vw 0.5vw !important;
    border-radius: 0.5vw !important;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    background-image: url('../Images/JoinButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 1rem !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.p-tb-register-btn {
    /* border: 1px solid #fff !important; */
    border: none;
    padding: 0vw 0.5vw !important;
    border-radius: 0.5vw !important;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    background-image: url('../Images/JoinButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 1rem !important;
    text-align: center !important;
    font-weight: 600 !important;
    position: absolute;
    right: 5px;
}

.mobileView .p-tb-register-btn {
    padding: 1.5% !important;
    border-radius: 0.5vh !important;
    color: #fff !important;
    font-size: 1.5vh !important;
    line-height: 1.5vh !important;
    font-weight: 400 !important;
    position: absolute;
    right: 5px;
    top: 3px;
}

.second-bg {
    background-color: #474B6B;
}

.sectionBox {
    /* border: 1px solid #fff; */
    /* background-color: #474B6B; */
}

.tableFixHead {
    overflow: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-bottom: 3vw;
}

.mobileView .tableFixHead {
    overflow: auto;
    position: relative;
    width: 100%;
    height: 80vh;
}

.gameHistoryTableFixHead {
    overflow: auto;
    height: 54vh;
}

.tableFixDetailsHead {
    overflow: auto;
    height: 58vh;
}



.tableFilterHead {
    /* border-bottom: 1px solid #fff; */
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
}

.tableFixHead thead th {
    position: sticky;
    /* border-bottom: 1px solid #fff; */
    top: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}


.tableFixHead::-webkit-scrollbar {
    display: none;
}

.tableFixHead {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.historyPlayerTable {
    max-height: 100%;
    width: 100%;
    border: none;
    background: transparent;
}

.p-lb-table th,
.p-lb-table td {
    font-size: 1vw !important;
    color: #fff !important;
    font-weight: 600 !important;
    text-align: center;
}

.mobileView .p-lb-table th,
.mobileView .p-lb-table td {
    font-size: 1.2vh !important;
    color: #fff !important;
    font-weight: 400 !important;
    text-align: center;
}

.p-lb-table tbody td {
    color: #fff !important;
    background: transparent;
}

.mobileView .p-lb-table tbody td {
    color: #fff !important;
    background: transparent;
}


.cashier-modal .p-lb-table th {
    font-size: 0.8vw !important;
    background: transparent;
}

.cashier-modal .p-lb-table tbody td {
    font-size: 0.8vw !important;
}


.p-lb-table tbody tr {
    cursor: pointer;
}

/* .p-lb-table tbody tr:nth-child(odd) {
    background-color: #b302584e;
} */

.p-lb-table tbody tr:nth-child(even) {
    background-color: #37344d;
    border-bottom: 2px solid #242234;
}

.p-lb-table tbody tr:nth-child(odd) {
    background-color: #37344d;
    border-bottom: 2px solid #242234;
}

.mobileTableList .tableListBox:nth-child(odd) {
    background-color: #37344d;
    border-left: 10px solid #b30258;
    color: #fff;
    /* border-bottom: 2px solid #242234; */
    margin-bottom: 0.2rem;
}

.mobileTableList .tableListBox:nth-child(even) {
    background-color: #37344d;
    color: #fff;
    /* border-bottom: 2px solid #242234; */
    border-left: 10px solid #8E52E9;
    margin-bottom: 0.2rem;
}

.p-lb-table tbody tr:hover {
    background-color: #8E52E9;
}

.p-lb-table tbody tr:hover>td {
    color: #fff !important;
}

.p-lb-table thead th {
    background-color: #474B6B !important;
}

.p-relative {
    position: relative;
}

.bannerImage {
    position: absolute;
    bottom: 5vh;
    left: 0;
    width: 100%;
    height: 54vh;
    border: 1px solid #8025F3;
}

.staticImg {
    width: 100%;
    height: 100%;
    background-image: url('../Images/AddBannerSec.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.blinkImage {
    width: 100%;
    height: 100%;
    background-image: url('../Images/AddBanner.jpg');
    background-size: 100% 100%;
    /* animation: blink 2.5s infinite; */
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.textSection h4 {
    color: #fff;
    margin-bottom: 0.2rem;
    font-size: 2.5vh;
}

.textSection p {
    color: #838383;
    margin-bottom: 0.2rem;
    font-size: 2vh;
}

.mbtextSection {
    padding: 0px 0.5rem;
}

.mbtextSection h4 {
    color: #fff;
    margin-bottom: 0.2rem;
    font-size: 1.5vh;
}

.mbtextSection p {
    color: #838383;
    margin-bottom: 0.2rem;
    font-size: 1vh;
}

.pokerLogo {
    align-items: center;
    justify-content: right;
    background-color: #474B6B;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
}

.pokerLogo img {
    height: 4vw;
    max-width: 80%;
    margin-top: 0.3rem;
}

.mbPokerLogo {
    background-color: #474B6B;
}

.mbPokerLogo img {
    margin: 0.25rem;
}

.leftTime {
    align-items: center;
    background-image: linear-gradient(180deg, #E4BB5A, transparent);
    font-size: 1.5vw;
    color: #fff;
    padding: 0.950vw;
    /* border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px; */
    font-weight: 500;
}

.leftTime img {
    width: 2vw;
    margin-right: 0.5vw;
    margin-bottom: 0.2vw;
}

.mbPokerLogo {
    background-color: #474B6B;
}

.mbPokerLogo img {
    margin: 0.25rem;
}

.userProfile {
    align-items: center;
    justify-content: left;
    background-color: #474B6B;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
}

.mbuserProfile {
    align-items: center;
    justify-content: left;
    background-color: #474B6B;
}

.mbuserProfile .brandLogo {
    padding: 0.5rem;
    width: 50%;
}

.mbuserProfile .brandLogo img {
    width: 70%;
}

.mbuserProfile .img img {
    border-radius: 50%;
    max-height: 5vh;
    border-radius: 50%;
    border: 0.5vh solid #8025F3;
}

.sidebarUserImg {
    padding-bottom: 0.5rem;
}

.sidebarUserImg img {
    height: 12.250vh;
    border-radius: 50%;
    border: 0.5vh solid #8025F3;
}

.sidebarUserImg .userInfo {
    margin-top: 1rem;
    background-color: #474B6B;
    padding-bottom: 0.5rem;
    text-align: left;
    padding-left: 1rem;
}

.sidebarUserImg .timeInfo {
    background-color: #E4BB5A;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    padding-left: 1rem;
}

.mobileView .sidebarUserImg .timeInfo {
    background-color: #E4BB5A;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    text-align: center;
    padding-left: 1rem;
}

.sidebarUserImg .userBalance {
    font-weight: normal;
    font-size: 1.2rem;
}

.sidebarIcon {
    width: 28px;
    margin-right: 1rem;
}

.plainText {
    color: #8B8A8A;
    font-size: 2.5vh;
    margin: 0;
}

.userName {
    color: #fff;
    font-size: 1.5vw;
    font-weight: 700;
    margin: 0;
}

.userTime {
    color: #8025F3;
    margin: 0;
    font-size: 2.5vh;
}


.mobileView .sidebarUserImg .userTime {
    font-size: 2vh;
    margin: 0;
}

.error-text {
    color: #DC3545 !important;
    font-size: 12px;
}

.userBalance {
    color: #fff;
    margin: 0;
    font-size: 0.8vw;
    font-weight: normal;
}

.userVipLevel {
    color: #E4BB5A;
    font-size: 0.8vw;
    margin: 0;
}

.mbUserName {
    color: #fff;
    font-size: 1.5vh;
    font-weight: 700;
    margin: 0;
}

.mobileView .userName {
    font-size: 2vh;
}

.mobileView .userBalance {
    font-size: 1.5vh;
}


.mbUserBalance {
    color: #E4BB5A;
    margin: 0;
    font-size: 1.5vh;
}

.tableUserInfo {
    margin-top: 3rem;
}

.info-btn {
    /* border: 1px solid #fff !important; */
    border: none;
    width: 100%;
    padding: 1% !important;
    padding-left: 0vw !important;
    border-radius: 0.5vw !important;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 2.2vh !important;
    text-align: center !important;
    font-weight: 600 !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}


.infoTable {
    /* border: 1px solid #fff; */
    background-color: #474B6B;
}

.mobileView .infoTable {
    border: none !important;
    background-color: #474B6B;
}

.infoTable .dateTime {
    color: #fff;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 1vw;
}

.mobileView .infoTable .dateTime {
    color: #fff;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 1.5vh;
}

.joinTableSec {
    text-align: center;
}

.join-tbl-btn {
    padding-left: 0 !important;
    margin: 1.5vh;
    width: 70%;
    align-items: center !important;
    text-align: center !important;
    font-size: 2vh;
    background-image: url('../Images/LobbyButtonHover.png');
}

.tableInfoHeader {
    border-bottom: 1px solid #fff;
    background-color: #fff;
    text-align: center;
    position: relative;
    padding-top: 0.5rem !important;
}

.tableInfoHeader h4 {
    font-weight: bold;
    font-size: 1.1vw;
}

.tournamentTableInfoHeader {
    border-bottom: 1px solid #fff;
    background-color: #fff;
    position: relative;
    padding-top: 0.5rem !important;
}

.tournamentTableInfoHeader h4 {
    font-weight: bold;
    font-size: 1.2vw;
    margin-top: 0.250px;
    margin-bottom: 0.250px;
}

.mobileView .tournamentTableInfoHeader {
    border-bottom: 1px solid #fff;
    background-color: #fff;
    position: relative;
    padding-top: 0.5rem !important;
}

.mobileView .tournamentTableInfoHeader h4 {
    font-weight: bold;
    font-size: 1.5vh;
    margin-top: 0.250px;
    margin-bottom: 0.250px;
}

.framePic {
    width: 4vw;
    opacity: 0.5;
}

.activeProfile {
    border: 0.2vw solid #fff;
}

.activeProfileFrame {
    zoom: 1.5;
    opacity: 1;
    border: 0.2vw solid #E4BB5A;
    border-radius: 10px;
}

.FrameName {
    opacity: 0.5;
}

.activeFrameName {
    opacity: 1;
    color: #E4BB5A;
    font-weight: bold;
}

.edtAvtarBtn {
    /* border: 1px solid #fff !important; */
    border: none;
    padding: 1% 2% !important;
    border-radius: 0.5vw !important;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 2vh !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.defaultBtn {
    /* border: 1px solid #fff !important; */
    border: none;
    padding: 1% 2% !important;
    border-radius: 0.5vw !important;
    background-color: #4A4D56;
    color: #fff !important;
    font-size: 2vh !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.mobileView .edtAvtarBtn {
    padding: 3% 4% !important;
    border-radius: 0.5vh !important;
    font-size: 1.5vh !important;
    line-height: 1.5vh;
    font-weight: 400 !important;
}

.edtAvtarBtn:hover {
    background-image: url('../Images/LobbyButtonHover.png');
}

.playerProfileVipWithFrame {
    text-align: center;
    width: 10vw;
    height: 10vw;
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
}

.playerProfileVipWithFrame img {
    width: 6vw;
}

.playerProfileWithFrame {
    text-align: center;
    width: 8vw;
    height: 8vw;
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
}

.playerProfileWithFrame img {
    width: 4vw;
}

.playerProfileWithFrameLobby {
    text-align: center;
    width: 4vw;
    height: 4vw;
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
}

.vipLevel1 {
    background-image: url('../Images/level/01.png');
}

.vipLevel2 {
    background-image: url('../Images/level/02.png');
}

.vipLevel3 {
    background-image: url('../Images/level/03.png');
}

.vipLevel4 {
    background-image: url('../Images/level/04.png');
}

.vipLevel5 {
    background-image: url('../Images/level/05.png');
}

.vipLevelLobby1 {
    background-image: url('../Images/level/1.png');
}

.vipLevelLobby1 img {
    width: 3vw;
}

.vipLevelLobby2 {
    background-image: url('../Images/level/2.png');
}

.vipLevelLobby2 img {
    width: 2.5vw;
}

.vipLevelLobby3 {
    background-image: url('../Images/level/3.png');
}

.vipLevelLobby3 img {
    width: 2.5vw;
}

.vipLevelLobby4 {
    background-image: url('../Images/level/4.png');
}

.vipLevelLobby4 img {
    width: 2vw;
}

.vipLevelLobby5 {
    background-image: url('../Images/level/5.png');
}

.vipLevelLobby5 img {
    width: 2vw;
}

/* .plyrProfilePicMain {
    border-radius: 50%;
    cursor: pointer;
    width: 8vw;
} */


.plyrProfilePic {
    border-radius: 50%;
    margin: 1vw;
    cursor: pointer;
    width: 5vw;
}

.tableInfoHeader img {
    width: 3vh;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.myinfo-details-sidebar {
    padding-top: 0.5rem;
}

.main-heading {
    color: #fff;
    text-align: center;
    padding: 5px;
    /* border-bottom: 1px solid #fff; */
    position: relative;
    background-color: #474B6B;
}

.main-heading h2 {
    margin-top: 0.250rem;
    margin-bottom: 0.250rem;
    font-size: 1.2vw;
}

.mobileView .main-heading h2 {
    margin-top: 0.250rem;
    margin-bottom: 0.250rem;
    font-size: 1.5vh;
}


.second-heading {
    color: #fff;
    text-align: center;
    padding: 5px;
    position: relative;
}

.second-heading h2 {
    margin-top: 0.250rem;
    margin-bottom: 0.250rem;
    font-size: 1.2vw;
}

.theme-bg {
    background-color: #242234;
}

.details-sidebar {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: #37344d;
    /* border-right: 1px solid #474b6b; */
}

.main-heading img {
    width: 3vh;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.second-heading img {
    width: 3vh;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}


.avtarBtnSection {
    text-align: right;
}

.avtarBtnSection button {
    margin: 10px;
}

.backButtonSection {
    text-align: left;
}

.backButtonSection button {
    margin: 10px;
}

.plyerProfile .form-control {
    background: transparent !important;
    color: #fff !important;
}

.plyerBuyChips .form-control {
    background: #474b6b !important;
    color: #fff !important;
}

.plyerProfile label {
    color: #fff;
    font-size: 1.2vw;
    margin-bottom: 0.5rem;
}

.mobileView .plyerProfile {
    padding: 0px 0.5rem;
}

.mobileView .plyerProfile label {
    font-size: 1.2vh;
}

.plyerProfileAvtar {
    text-align: center;
    justify-content: center;
    display: flex;
}

.vip-level-section {
    margin-top: 2vw;
    text-transform: uppercase;
    /* color: #8025F3; */
}

.vip-level-section span {
    color: #E4BB5A;
}

.playerProfileInfo {
    color: #fff;
    padding: 1rem;
}

.playerProfileInfo p {
    font-size: 1.2vw;
    color: #fff;
    margin-bottom: 0.5rem;
}

.playerProfileInfo p span {
    color: #959595;
    font-weight: bold;
}

.mobileView .playerProfileInfo p {
    font-size: 1.2vh;
}

.mobileView .playerProfileInfo p span {
    font-size: 1.2vh;
}


.historyCard {
    width: 4.5vh;
}

.historyPagination {
    margin-bottom: 1rem;
}

.historyPagination .pagination {
    margin-bottom: 0rem;
}

.historyPagination .pagination .page-item {
    border: none !important;
}

.historyPagination .pagination .page-link {
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 5px !important;
    background-image: url('../Images/LobbyButton.png');
    color: #ffF !important;
    border: none !important;
    font-size: 1vw;
    padding: 0.1vw 0.5vw;
}

.mobileView .historyPagination .pagination .page-link {
    font-size: 1.5vh;
    padding: 0.1vh 0.5vh;
}

.historyPagination .pagination .page-link:hover,
.historyPagination .pagination .active .page-link,
.historyPagination .pagination .disabled .page-link {
    background-image: url('../Images/LobbyButtonHover.png');
}


.historyTitle {
    border-bottom: 1px solid #fff;
    color: #fff;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.2vw;
    background-color: #474B6B;
}

.gameHistoryData .historyTitle {
    border-bottom: 1px solid #fff;
}

.backBtn {
    float: right;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
}

.backBtn img {
    width: 1.5vw;
}

.gameNameTitle {
    margin-top: 0.5rem;
    font-size: 1.2vw;
}

.gameNameTitle span {
    color: #959595;
}

.acount-security-table {
    padding: 0.5vw;
}

.acount-security-table tr {}

.acount-security-table th,
.acount-security-table td {
    font-size: 1.2vw !important;
    color: #fff !important;
    font-weight: 600 !important;
    text-align: left;
}

.setting-table th,
.setting-table td {
    font-size: 2vh !important;
    color: #fff !important;
    font-weight: 600 !important;
    text-align: left;
}



.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1111111;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
    background-color: #242234;
}

.sidenav a {
    display: block;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #F1F1F1;
}

.sidenav .sideBarClosebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 35px;
    margin-left: 50px;
}

.sideBarMenubtn {
    position: absolute;
    right: 5%;
    padding: 5px;
    border: 1px solid #fff;
    border-radius: 50%;
}

.sideBarMenubtn img {
    width: 1.5rem;
    height: auto;
}

.mbsideBarMenubtn {
    position: absolute;
    right: 5%;
}

.mbsideBarMenubtn img {
    width: 1.8rem;
    height: 1.8rem;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #fff;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

.sidebarBtn {
    border-bottom: 1px solid #959595 !important;
    width: 100%;
    padding: 2% !important;
    border-radius: 0% !important;
    color: #fff !important;
    font-size: 1.2vw !important;
    text-align: left !important;
    font-weight: 600 !important;
}

.mobileView .sidebarBtn {
    font-size: 1.5vh !important;
}

.modalLoading img {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10vh;
    transform: translate(-50%, -50%);
}

.historyCard {
    width: 1vw;
}

.mobileView .historyCard {
    width: 2vw;
}

.gameDateilsTitle {
    color: #fff;
    font-size: 1vw;
}

.preFlopTitle {
    text-align: center;
    color: #629fe6;
}

.flopTitle {
    text-align: center;
    color: #db756f;
}

.flopRaw {
    background-color: #db756f !important;
}

.turnTitle {
    text-align: center;
    color: #8cd379;
}

.turnRaw {
    background-color: #8cd379 !important;
}

.riverTitle {
    text-align: center;
    color: #fecd34;
}

.riverRaw {
    background-color: #fecd34 !important;
}

.showDownTitle {
    text-align: center;
    color: #6f87df;
}

.h-10 {
    height: 10%;
}

.h-100 {
    height: 100%;
}

.h-90 {
    height: 90%;
}

.h-80 {
    height: 80%;
}

.lobbyFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #000;
    height: 5vh;
    padding: 0.5vh;
}

.lobbyFooter .footerLeft {
    float: left;
}

.lobbyFooter .footerRight {
    float: right;
}

.lobbyFooter .footerText {
    margin-left: 0.1rem;
    color: #fff;
    font-size: 2vh !important;
}

.lobbyFooter .footerLeft img,
.lobbyFooter .footerRight img {
    height: 4vh;
    padding: 0.5vh;
    margin-left: 1rem;
}


.mobileView .lobbyFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #000;
    height: 8vh;
    padding: 0vh;
}

.mobileView .lobbyFooter .footerLeft {
    float: none;
    background-color: #000;
    padding: 0.5vh;
}

.mobileView .lobbyFooter .footerRight {
    float: none;
    text-align: center;
    background-color: #000;
    padding: 0.5vh;
}

.mobileView .lobbyFooter .footerText {
    margin-left: 0.1vh;
    color: #fff;
    font-size: 1vh !important;
}

.mobileView .lobbyFooter .footerLeft img,
.mobileView .lobbyFooter .footerRight img {
    height: 2vh;
    padding: 0.1vh;
    margin-left: 1vw;
}

.mr-1 {
    margin-right: 0.5rem;
}

.text-mute {
    color: #959595 !important;
}

.cahsierForm {
    padding: 0.5rem;
    /* padding-right: 10%; */
}

/* 

.cahsierForm .text-mute {
    color: #c5c5c5 !important;
    font-size: 0.8vw;
}

.cahsierForm .form-group {
    width: 100%;
}


.cahsierForm input {
    width: 100%;
}

.cahsierForm button {
    width: 100%;
}

.cahsierForm input[type="text"],
.cahsierForm input[type="file"],
.cahsierForm select,
.cahsierForm input[type="password"] {
    border: none !important;
    border-bottom: 1px solid #fff !important;
    background: transparent;
    outline: none;
    height: 40px;
    color: #fff;
    font-size: 16px;
    border-radius: 0px;
    outline: none !important;
    box-shadow: none !important;
}

.cahsierForm select option {
    font-size: 1vw;
    border: none !important;
    outline: none !important;
    background-color: #8025F3;
    padding: 0.5vw;
}

.mobileView .cahsierForm select option {
    font-size: 1.5vh;
    border: none !important;
    outline: none !important;
    background-color: #8025F3;
    padding: 0.5vw;
} */

.inputWithIcon input[type="text"] {
    padding-left: 40px;
}

.inputWithIcon {
    position: relative;
}

.inputWithIcon i {
    position: absolute;
    left: 0;
    top: 8px;
    padding: 9px 8px;
    color: #aaa;
    transition: 0.3s;
}

.inputWithIcon input[type="text"]:focus+i {
    color: dodgerBlue;
}

.inputWithIcon.inputIconBg i {
    background-color: #aaa;
    color: #fff;
    padding: 9px 4px;
    border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg input[type="text"]:focus+i {
    color: #fff;
    background-color: dodgerBlue;
}

.tableListBox {
    border-left: 10px solid #8025F3;
    padding-left: 0.5rem;
}

.tableListBox p {
    font-weight: 600;
    font-size: 1.5vh;
}

.tableListBox .gameTableName {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tableListBox .gameBuyIn {
    color: #fecd34;
}

.tableListBox .gameType {
    color: #c5c5c5;
}

.tableListBox .gameDateTime {
    color: #fff;
}

.wrapper-div {
    line-height: 5vh;
}

.z-index-0 {
    z-index: 0;
}

.text-danger {
    color: #DC3545;
}

.hour-buy-dropdown {
    background-color: #474b6b !important;
}

.lobbyListing {
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem !important;
}

.lobbySideBox {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-bottom: 2.5vw;
}


.bannerImageSec {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 12em;
    /* height: calc(((5vw - 50px) + (30vh - 10px)) * 1); */
}

.bannerImageSec img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* height: calc(((5vw - 50px) + (30vh - 10px)) * 1); */
}

.buttonHeader {
    /* background-color: #683565; */
    padding-top: 0.5rem;
    min-height: 8%;
    /* padding-bottom: 0.5rem; */
}



.infotableFixHead {
    overflow: auto;
    height: calc(((7vw - 50px) + (30vh - 10px)) * 1);
}

.mobileView .infotableFixHead {
    overflow: auto;
    height: 70vh;
}

.gameHistoryTableFixHead {
    overflow: auto;
    height: 54vh;
}

.infotableFixHead thead th {
    position: sticky;
    top: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}


.infotableFixHead::-webkit-scrollbar {
    display: none;
}

.infotableFixHead {
    -ms-overflow-style: none;
    scrollbar-width: none;
}



.avtarSection {
    height: 55vh;
    overflow-y: scroll;
    overflow-x: hide;
    /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.avtarSection::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.avtarSection {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.tables,
.players,
.payouts,
.blinds {
    padding: 0.5rem;
    padding-right: 0rem !important;
}

.buyHour-card-body {
    padding: 10px;
}

.buyHourBox {
    border: 1px solid #37344d;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
}

.package-name-header {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #37344d;
    padding-bottom: 10px;
}

.package-name-header p {
    margin: 0px !important;
}

.mobileView .package-name-header p {
    font-size: 1vh;
}

.package-box {
    min-height: 100%;
    background: transparent;
    text-align: center;
}

.package-box-body ul {
    text-align: left;
    margin: 0;
    padding: 0px;
    padding-top: 10px;
    list-style-type: none;
}

.package-box-body ul>li {

    padding-left: 10px;
    text-indent: -5px;
    border: none;
    color: #636774;
    /* border-bottom: 1px dashed #8E52E9; */
}

.mobileView .package-name-header ul>li {
    font-size: 1.5vh
}

.package-box-body ul>li:before {
    content: "-";
    margin-right: 10px;
    text-indent: -5px;
}

.buyPackageBox {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hide;
    /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.buyPackageBox::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.buyPackageBox {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.timeLeftText {
    color: #b7025e !important;
}



.buy-package-btn {
    background-repeat: no-repeat;
    background-image: url('../Images/LobbyButtonHover.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-size: 0.8vw !important;
    text-align: center !important;
    z-index: 1;
    position: relative;
}

.mobileView .buy-package-btn {
    font-size: 1vh !important;
}

.mobileView .cashout,
.mobileView .deposit,
.mobileView .coHistory,
.mobileView .buyHours,
.mobileView .dHistory,
.mobileView .allTra {
    height: 100%;
    padding: 0;
    background-color: #242234;
}

.cashier-modal {
    overflow: hidden;
    height: 65vh;
}

.cashier-modal .cashier-modal-body {
    overflow: scroll;
    height: 60vh;
}

.cashier-modal .cashier-modal-body::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
.cashier-modal .cashier-modal-body::-webkit-scrollbar-thumb {
    background: transparent;

}

.cashier-modal .modal-sidebar {
    background-color: #37344d;
    /* border-right: 1px solid #474b6b; */
}

.cashier-modal .modal-sidebar hr {
    margin: 0.5rem;
}

.cashier-modal .modal-sidebar .modal-sidebar-header {
    margin-top: 0.5vw;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5vw;
    /* border-bottom: 1px solid #ffffff3c; */
}

.cashier-modal .modal-sidebar .modal-sidebar-header .gameLogo {
    width: 80%;
    height: auto !important;
    margin-bottom: 5px;
}

.cashier-modal .cashier-modal-card .cashier-modal-heading {
    color: #fff;
    text-align: start;
    padding: 15px;
    position: relative;
}



.cashier-modal .cashier-modal-card .cashier-modal-heading img {
    width: 2vh;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}


.cashier-heading {
    color: #fff;
    text-align: start;
    padding: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ffffff3c;
    position: relative;
}

.cashier-heading h2 {
    margin-top: 0.250rem;
    margin-bottom: 0.250rem;
    font-size: 1.2vw;
}

.mobileView .cashier-heading h2 {
    margin-top: 0.250rem;
    margin-bottom: 0.250rem;
    font-size: 1.2vh !important;
}


.cashier-modal .cashier-modal-card .cashier-modal-heading h2 {
    margin-top: 0.250rem;
    margin-bottom: 0.250rem;
    font-size: 1.2vw;
}

.mobileView .cashier-modal .cashier-modal-card .cashier-modal-heading h2 {
    margin-top: 0.250rem;
    margin-bottom: 0.250rem;
    font-size: 1.5vh;
}

.cashier-modal .modal-sidebar .modal-sidebar-button {
    border: none !important;
    width: 100%;
    border-radius: 0px !important;
    color: #fff !important;
    font-size: 0.7vw !important;
    text-transform: uppercase;
    text-align: start !important;
    font-weight: 600 !important;
    z-index: 1;
    position: relative;
    cursor: default;
    padding: 10px;
}


.cashier-modal .modal-sidebar .modal-sidebar-button.active {
    border-left: 3px solid #8025F3 !important;
    background-color: #242234 !important;
}




.cashier-box h1 {
    color: #fff;
    text-align: center;
    margin-top: 0;
}

.cashier-box label,
.cashier-box p {
    margin: 0;
    padding: 0;
    color: #fff;
}

.cashier-box label {
    color: #777777;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 5px;
}

.cashier-box input::-ms-input-placeholder {
    color: #4A4D56;
}

.cashier-box input::placeholder {
    color: #4A4D56;
}

.cashier-box .profileDetails {
    color: #fff;
    font-size: 12px;
    margin-bottom: 5px;
}


.cashier-box .form-group {
    margin-bottom: 10px;
}

.cashier-box input {
    width: 100%;
}

.cashier-box button {
    width: 100%;
    margin-bottom: 20px;
}

.cashier-box input[type="text"],
.cashier-box input[type="file"],
.cashier-box select,
.cashier-box input[type="password"] {
    border: none;
    border: 1px solid #323045;
    border-radius: 8px;
    background: #29273b;
    outline: none;
    height: 40px;
    color: #fff;
    font-size: 12px;
    padding: 10px;
}


.cashier-box input[type="text"]:focus,
.cashier-box input[type="password"]:focus {
    border: 1px solid #545177;
}

.cashier-box input[type="submit"] {
    border: none;
    outline: none;
    height: 40px;
    background: #fb2525;
    color: #fff;
    font-size: 18px;
    border-radius: 20px;
}

.cashier-box input[type="submit"]:hover {
    cursor: pointer;
    background: #ffc107;
    color: #000;
}

.cashier-box a {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

.cashier-box a:hover {
    text-decoration: underline;
}

.deposit_withdraw_btn {
    border: none;
    outline: none;
    background-image: url('../Images/LobbyButtonHover.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 16px;
    border-radius: 0.5vw !important;
    cursor: pointer;
    margin-top: 15px;
    padding: 10px;
}

.claim_btn {
    border: none;
    outline: none;
    background-image: url('../Images/LobbyButtonHover.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-weight: 600 !important;
    border-radius: 0.5vw !important;
    cursor: pointer;
    margin: 0px !important;
    padding: 5px;
}

.mobileView .cashier-modal .modal-sidebar .modal-sidebar-button {
    font-size: 0.8vh !important;
    padding: 5px;
}

.mobileView .cashier-modal .modal-sidebar hr {
    margin: 0.3rem;
}

.mobileView .package-name-header {
    padding-bottom: 5px;

}

.mobileView .package-box-body ul>li {
    font-size: 0.9vh !important;

}

.mobileView .cahsierForm .login_header h6 {
    font-size: 1.2vh !important;

}

.mobileView .cashier-modal .cashier-modal-card .cashier-modal-heading {
    padding: 10px;
}

.mobileView .cashier-modal .cashier-modal-card .cashier-modal-heading h2 {
    font-size: 1.2vh;
}

.invalid-text {
    color: #DC3545;
    font-size: 0.7vw;
}