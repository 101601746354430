.cursor-pointer {
    cursor: pointer;
}

.login-box {
    min-height: 700px;
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #242234;
    transform: translate(-50%, -50%);
    /* background: #222; */
    padding: 20px;
    border-radius: 15px;
    /* box-shadow: 0 0 20px #ffffff40; */
    border: 2px solid #323045;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.mobile-login-box {
    width: 80%;
}
.login-logo .gameLogo{
    height: 60px;
    margin-bottom: 10px;
}
.term_condition_text{
    text-align: center;
    font-size: 12px;
    color: #4A4D56!important;
}

.extra_text{
    text-align: center;
    color: #4A4D56!important;
}


.term_condition_text span{
    color: #8025F3;
}
.login-box h1 {
    color: #fff;
    text-align: center;
    margin-top: 0;
}

.login-box label,
.login-box p {
    margin: 0;
    padding: 0;
    color: #fff;
}

.login-box label {
    color: #4A4D56;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 5px;
}


.login-box .form-group {
    margin-bottom: 10px;
}

.login-box input {
    width: 100%;
}

.login-box button {
    width: 100%;
    margin-bottom: 20px;
}

.login-box input[type="text"],
.login-box input[type="password"] {
    border: none;
    border: 1px solid #323045;
    border-radius: 8px;
    background: #29273b;
    outline: none;
    height: 40px;
    color: #fff;
    font-size: 12px;
    padding: 10px;
}


.login-box input[type="text"]:focus,
.login-box input[type="password"]:focus {
    border: 1px solid #545177;
}

.login-box input[type="submit"] {
    border: none;
    outline: none;
    height: 40px;
    background: #fb2525;
    color: #fff;
    font-size: 18px;
    border-radius: 20px;
}

.login-box input[type="submit"]:hover {
    cursor: pointer;
    background: #ffc107;
    color: #000;
}

.login-box a {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

.login-box a:hover {
    text-decoration: underline;
}

.avatar {
    width: auto;
    height: 100px;
    margin-bottom: 2rem;
}

.invit-only {
    margin: 0px 0;
    color: #fff;
    font-size: 14px;
}

.invit-only label {
    display: flex;
    align-items: center;
}

.invit-only label input[type="checkbox"] {
    margin-right: 1px;
}

.referral-code {
    color: white;
}

.signin_btn {
    border: none;
    outline: none;
    background-image: url('../Images/LobbyButtonHover.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 16px;    
    border-radius: 0.5vw !important;
    cursor: pointer;
    margin-top: 15px;
    padding: 10px;
}

.signin_btn:hover {
    background-image: url('../Images/LobbyButtonHover.png');
}

.connect_btn {
    border: 1px solid #323045;
    border-radius: 8px;
    background: #29273b;
    outline: none;
    color: #fff;
    font-size: 16px;
    margin-top: 5px;
    margin-left: 10px;
    padding: 8px;
    text-align: start;

    /* border: none;
    outline: none;
    background-image: url('../Images/LobbyButtonHover.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 16px;    
    border-radius: 0.5vw !important;
    cursor: pointer;
    padding: 10px; */
}

.connect_btn:hover {
    background-image: url('../Images/LobbyButtonHover.png');
}

.signup_btn {
    border: none;
    outline: none;
    height: 40px;
    background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 18px;
    border-radius: 0.5vw !important;
    cursor: pointer;
}

.signup_btn:hover {
    background-image: url('../Images/LobbyButtonHover.png');
}


.login_btn {
    border: none;
    outline: none;
    height: 40px;
    background-image: url('../Images/LobbyButtonHover.png');
    background-size: 100% 100%;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 1.2vw;
    border-radius: 0.5vw !important;
    cursor: pointer;
}
/* 
.omb_login{
    position: absolute;
    width: 100%;
    padding-right: 40px;
    bottom: 40px;
} */

.omb_login .omb_loginOr {
    position: relative;
    font-size: 1.5em;
    color: #aaa;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.omb_login .omb_loginOr .omb_hrOr {
    height: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.omb_login .omb_loginOr .omb_spanOr {
    display: block;
    position: absolute;
    left: 40%;
    top: 0em;
    margin-left: -1.5em;
    background-color: #242234;
    width: 10em;
    text-align: center;
    font-size: 14px;
}

.social_btn_box {
    margin-top: 2rem;
    justify-content: center;
    gap: 1rem;
    display: flex;
}
.login_header{
    border-bottom: 1px solid #323045;
}
.no_border{
    border:none!important;
}
.login_header h6{
    margin-bottom: 0px;
    color: #4A4D56;
    cursor: pointer;
    padding: 10px;
    padding-bottom: 10px;
}
.login-header-title{
    color: #fff;
}
.login_header h6.active{
    color: #fff;
    border-bottom: 3px solid #8025F3;
}
.social_btn {
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
}
.connect_icon {
    width: 30px !important;
    height: 30px !important;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
    margin-right: 10px;
}

.fb_login {
    background-image: url('../Images/Icons/Facebook.png');
    background-size: 100% 100%;
}

.g_login {
    background-image: url('../Images/Icons/Google.png');
    background-size: 100% 100%;
}